import apiClient from '@/services/axios'

const getAll = async () => {
  try {
    const res = await apiClient.get('/city')
    return res.data
  } catch (e) {
    console.log('error when getting city data with error: ' + e)
    return []
  }
}

export {
  getAll,
}
