import apiClient from '@/services/axios'

const resetPassword = async (data, role) => {
  data.role = role
  const res = await apiClient.post('/auth/reset-password', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const changePassword = async (id, data) => {
  const res = await apiClient.post('/auth/change-password-admin/' + id, data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const register = async (data) => {
  const res = await apiClient.post('/auth/register', data, {})
  if (res) {
    return res
  } else {
    return null
  }
}

const getCurrentProfile = async () => {
  const res = await apiClient.get('/api/users/profile', {})
  if (res) {
    return res
  } else {
    return null
  }
}

const getUserProfileFromId = async (id) => {
  const res = await apiClient.get('/api/users/' + id, {})
  if (res) {
    return res
  } else {
    return null
  }
}

export {
  resetPassword,
  register,
  changePassword,
  getCurrentProfile,
  getUserProfileFromId,
}
